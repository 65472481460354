import styled from "styled-components"
import { Button } from "../Button"

export const CommonTitle = styled.h1`
  font-family: ${props => props.theme.typography.h1.fontFamily};
  font-weight: ${props => props.theme.typography.h1.fontWeight};
  font-size: ${props => props.theme.typography.h1.fontSize};
  line-height: ${props => props.theme.typography.h1.lineHeight};
  letter-spacing: ${props => props.theme.typography.h1.letterSpacing};
  margin: 0 0 20px 0;
  color: ${({ theme }) => theme.palette.primary.main};
`

export const CommonSubtitle = styled.h2`
  font-family: ${props => props.theme.typography.h2.fontFamily};
  font-weight: ${props => props.theme.typography.h2.fontWeight};
  font-size: ${props => props.theme.typography.h2.fontSize};
  line-height: ${props => props.theme.typography.h2.lineHeight};
  letter-spacing: ${props => props.theme.typography.h2.letterSpacing};
  margin: 0 0 15px 0;
  color: ${({ theme }) => theme.palette.primary.main};
`

export const CommonParagraph = styled.p`
  font-family: ${props => props.theme.typography.body.fontFamily};
  font-weight: ${props => props.theme.typography.body.fontWeight};
  font-size: ${props => props.theme.typography.body.fontSize};
  line-height: ${props => props.theme.typography.body.lineHeight};
  letter-spacing: ${props => props.theme.typography.body.letterSpacing};
  margin: 0 0 15px 0;
  color: ${({ theme }) => theme.palette.primary.main};
`
export const CommonSubParagraph = styled.p`
  font-family: ${props => props.theme.typography.info.fontFamily};
  font-weight: ${props => props.theme.typography.info.fontWeight};
  font-size: ${props => props.theme.typography.info.fontSize};
  line-height: ${props => props.theme.typography.body.lineHeight};
  letter-spacing: ${props => props.theme.typography.body.letterSpacing};
  margin: 0 0 15px 0;
  color: ${({ theme }) => theme.palette.primary.main};
`

export const ErrorMessage = styled(CommonParagraph)`
  color: ${({ theme }) => theme.palette.error.main};
  font-size: 14px;
  z-index: 10;
`

export const InfoMessage = styled(CommonParagraph)`
  font-family: ${props => props.theme.typography.info.fontFamily};
  font-weight: ${props => props.theme.typography.info.fontWeight};
  font-size: ${props => props.theme.typography.info.fontSize};
`
