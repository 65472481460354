import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { ImageWithLinkOrNotView } from "./ImageWithLinkOrNot.view"

export enum enumFrom {
  STRAPI = "STRAPI",
  SALEOR = "SALEOR",
}

interface Props {
  data: Record<string, any>
  from?: enumFrom
  onClick?: Function
}
export function ImageWithLinkOrNotContainer(props: Props) {
  if (props.data) props = buildImageUrl(props)
  return <ImageWithLinkOrNotView {...props} />
}

function buildImageUrl(props: Props) {

  let baseURL = process.env.GATSBY_STRAPI_URL
  if (typeof props.from != "undefined") {
    switch (props.from) {
      case "STRAPI":
        baseURL = process.env.GATSBY_STRAPI_URL
        break
      case "SALEOR":
        baseURL = process.env.GATSBY_SALEOR_URL
        break
      default:
        baseURL = process.env.GATSBY_FRONTEND_URL
        break
    }
  }
  if (typeof baseURL == "undefined") {
    baseURL = "http://localhost:7000"
  }
  if (props.data.hasOwnProperty("Image")) {
    if (props.data.Image?.url) {
      if (!props.data.Image.url.includes("http")) {
        props.data.Image.url = new URL(props.data.Image.url, baseURL).toString()
      }
    }
  } else {
    if (props.data.url) {
      if (!props.data.url.includes("http")) {
        props.data.url = new URL(props.data.url, baseURL).toString()
      }
    }
  }
  return props
}
