import React from "react"
import { LoopCircleLoading } from "react-loadingg"

import { Wrapper } from "./Spinner.style"

interface Props {
  className?: string
  onClick?: () => void
}

export function SpinnerView({ className }: Props) {
  return (
    <Wrapper className={className}>
      <LoopCircleLoading color={"#7d7d7d"} />
    </Wrapper>
  )
}
