import React from "react"
import { Link } from "./../Link"
import { Image, ButtonStyled } from "./ImageWithLinkOrNot.style"
import { v4 as uuidv4 } from "uuid"
import noImage from "./../../../images/no-image.jpg"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

interface Props {
  className?: string
  objectFit?: string
  data: Record<string, any>
  onClick?: Function | undefined
}

export function ImageWithLinkOrNotView({
  className,
  data,
  objectFit,
  onClick = undefined,
}: Props) {
  var item = data
  if (data?.hasOwnProperty("Image")) {
    item = data.Image
  }

  // Si proprieté Link : il s'agit d'une image clickable balise <a>
  if (data?.hasOwnProperty("Link") && data.Link != "" && data.Link != null) {
    return (
      <Link id={data.id} link={data.Link}>
        <ImageOrDefaut
          className={className}
          key={uuidv4()}
          item={item}
          objectFit={objectFit}
        />
      </Link>
    )
  }
  // Si proprieté onCLick : il s'agit d'une image clickable balise <button>
  if (onClick) {
    return (
      <ButtonStyled onClick={onClick}>
        <ImageOrDefaut
          className={className}
          key={uuidv4()}
          item={item}
          objectFit={objectFit}
        />
      </ButtonStyled>
    )
  }
  return (
    <ImageOrDefaut
      className={className}
      key={uuidv4()}
      item={item}
      objectFit={objectFit}
    />
  )
}

const ImageOrDefaut = ({ className, item, objectFit }) => {
  if (!(item?.url || item?.localFile)) {
    // + "./../../../images/gatsby-astronaut.png"
    // const url = new URL("/images/gatsby-astronaut.png", process.env.GATSBY_FRONTEND_URL).toString()
    // return <Image className={className} src={url} alt="no image" />
    return <img className={className} src={noImage} alt="no image" />
  }
  const alt = item?.alternativeText || item?.alt || "grandes marques"
  if (item?.localFile) {
    return (
      <GatsbyImage
        key={uuidv4()}
        className={className}
        image={getImage(item.localFile)}
        objectFit={objectFit || "contain"}
        alt={alt}
      />
    )
  }

  return (
    <Image className={className} key={uuidv4()} src={item?.url} alt={alt} />
  )
}
