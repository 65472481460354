import React, { useEffect } from "react"

export const useClickedOutside = () => {
  const [clickedOutside, setClickedOutside] = React.useState<boolean>(false)
  const elementRef = React.useRef(null)

  const handleClickOutside = (e: MouseEvent) => {
    if (maybe(() => elementRef.current && e.target, null)) {
      setClickedOutside(!elementRef.current.contains(e.target as Node))
    }
  }

  const setElementRef = () => elementRef

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => document.removeEventListener("mousedown", handleClickOutside)
  }, [])

  return {
    clickedOutside,
    setElementRef,
  }
}

export const maybe = <T>(exp: () => T, d?: T) => {
  try {
    const result = exp()
    return result === undefined ? d : result
  } catch {
    return d
  }
}
