import React, { useEffect, useState } from "react"
import { isDomAvailable } from "."
import { breakpointsValues } from "../assets/media"

export const useDetectDevice = () => {
  if (!isDomAvailable()) {
    return false
  }
  const [width, setWidth] = useState<number>(window.innerWidth)
  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [])
  let isMobile: boolean = width <= breakpointsValues.md
  return isMobile
}
