import React from "react"
import IcoMoon from "react-icomoon"
import { enumFrom } from "../ImageWithLinkOrNot"
import { Icon } from "./Icon.style"

const iconSet = require("./selection.json")

interface IconProps {
  icon?: string
  color?: string
  size?: number
  data?: Record<string, any>
  from?: enumFrom
  onClick?: Function
  className?: string

  iconSet?: Object
  style?: Object
  title?: String
  disableFill?: Boolean
  removeInlineStyle?: Boolean
}

export const IconView = (props: IconProps) => {
  return <IconViewCore {...props} />
}


const IconViewCore = ({
  icon = undefined,
  color = "#7d7d7d",
  size = 15,
  data = undefined,
  from = undefined,
  onClick = () => {
    return null
  },
  className,
}: IconProps) => {
  if (icon) {
    return (
      <IcoMoon
        iconSet={iconSet}
        icon={icon}
        color={color}
        size={size}
        onClick={onClick}
        className={className}
      />
    )
  }
  if (data) {
    return <Icon data={data} from={from} onClick={onClick} />
  }
  return <></>
}
