/**
 * isDomAvailable
 * @description Checks to see if the DOM is available by checking the existence of the window and document
 * @see https://github.com/facebook/fbjs/blob/master/packages/fbjs/src/core/ExecutionEnvironment.js#L12
 */

export function isDomAvailable() {
  return (
    typeof window !== "undefined" &&
    !!window.document &&
    !!window.document.createElement
  )
}

export const isEditorjs = (json) => {
  try {
    let obj = JSON.parse(json)
    if ('entityMap' in obj) return false
    return true
  }
  catch { return false }
}

export const convertJsonToHtml = (json) => {
  if (isEditorjs(json)) return convertEditorjsJsonToHtml(json)
}

export const convertEditorjsJsonToHtml = (json) => {
  let obj = JSON.parse(json);
  let html = '';
  obj["blocks"].forEach(function (block, index) {
    switch (block['type']) {
      case 'paragraph':
        html += '<p>' + block['data']['text'] + '</p>';
        break;

      case 'header':
        html += '<h' + block['data']['level'] + '>' + block['data']['text'] + '</h' + block['data']['level'] + '>';
        break;

      case 'raw':
        html += block['data']['html'];
        break;

      case 'list':
        lsType = (block['data']['style'] == 'ordered') ? 'ol' : 'ul';
        html += '<' + lsType + '>';
        block['data']['items'].forEach(function (item, index) {
          html += '<li>' + item + '</li>';
        });
        html += '</' + lsType + '>';
        break

      case 'code':
        html += '<pre><code class="language-' + block['data']['lang'] + '">' + block['data']['code'] + '</code></pre>';
        break

      case 'image':
        html += '<div class="img_pnl"><img src="' + block['data']['file']['url'] + '" /></div>';
        break

      default:
        break
    }
  });
  return html;
}

export const convertEditorjsJsonToHtmlParagraph = (json) => {
  let obj = JSON.parse(json);
  let html = '';
  obj["blocks"].forEach(function (block) {
    if(block['type'] === 'paragraph') {
      html += '<p>' + block['data']['text'] + '</p>';
    }
  });
  return html;
}