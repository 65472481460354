import { createMedia } from "@artsy/fresnel"

export const breakpointsValues = {
  xs: 350,
  sm: 768,
  md: 980,
  lg: 1280,
}

const ExampleAppMedia = createMedia({
  breakpoints: breakpointsValues,
})

// Make styles for injection into the header of the page
export const mediaStyles = ExampleAppMedia.createMediaStyle()

export const { Media, MediaContextProvider } = ExampleAppMedia
