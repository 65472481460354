import styled from "styled-components"
import { Link } from "gatsby"

export const StyledLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: inherit;
  height: inherit;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  &.mobile {
    max-width: 470px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3em;
  }
`

export const StyledGatsbyLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: inherit;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  &.mobile {
    max-width: 470px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3em;
  }
`
