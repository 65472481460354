import { useStaticQuery, graphql } from "gatsby"

export const useQuerySEO = () => {
  const data = useStaticQuery(
    graphql`
    {
      strapiGmHeader {
        TagSEO {
          ... on STRAPI__COMPONENT_GLOBAL_SEO_PROPERTY {
            Name
            Property
          }
        }
      }
    }
    
    
    `
  )
  return data
}
