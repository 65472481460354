/**
 * promiseToFlyTo
 * @description
 */

import moment from "moment"

export function promiseToFlyTo(map, { zoom, center }) {
  return new Promise((resolve, reject) => {
    const baseError = "Failed to fly to area"

    if (!map.flyTo) {
      reject(`${baseError}: no flyTo method on map`)
    }

    if (typeof zoom !== "number") {
      reject(`${baseError}: zoom invalid number ${zoom}`)
    }

    const mapCenter = center || map.getCenter()
    const mapZoom = zoom || map.getZoom()

    map.flyTo(mapCenter, mapZoom, {
      duration: 2,
    })

    map.once("moveend", () => {
      resolve()
    })
  })
}

/**
 * getCurrentLocation
 * @description
 */

export function getCurrentLocation() {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      pos => resolve({ lat: pos.coords.latitude, lng: pos.coords.longitude }),
      err => reject(err)
    )
  })
}

export function getCenterLocation(
  marckers: Array<IPointRelaisColissimo>
): IPosition {
  let result = marckers.reduce((result, item) => {
    result.coordGeolocalisationLatitude += item.coordGeolocalisationLatitude
    result.coordGeolocalisationLongitude += item.coordGeolocalisationLongitude
    return result
  })

  return {
    lat: result.coordGeolocalisationLatitude / marckers.length,
    lng: result.coordGeolocalisationLongitude / marckers.length,
  }
}

export async function getMarkersLocation(
  shippingDate: Date,
  countryCode: string,
  address?: string,
  zipCode?: string,
  city?: string
): Promise<[IPointRelaisColissimo]> {
  if (!process.env.GATSBY_STRAPI_URL)
    throw new Error("process.env.GATSBY_STRAPI_URL is not define")

  const url =
    `${process.env.GATSBY_STRAPI_URL}/point-relais/colissimo?` +
    (address ? `address=${address}&` : "") +
    (zipCode ? `zipCode=${zipCode}&` : "") +
    (city ? `city=${city}&` : "") +
    `shippingDate=${moment(shippingDate).format("DD/MM/YYYY")}&` +
    `countryCode=${countryCode}`

  const response = await fetch(url)

  if (!response.ok) {
    throw new Error(response.statusText)
  }
  const body = await response.json()

  return body.result.map((x: any) => x as IPointRelaisColissimo)
}

export interface IPointRelaisColissimo {
  accesPersonneMobiliteReduite: boolean
  adresse1: string
  adresse2: string
  adresse3: string
  codePostal: string
  congesPartiel: boolean
  congesTotal: boolean
  coordGeolocalisationLatitude: number
  coordGeolocalisationLongitude: number
  distanceEnMetre: number
  horairesOuvertureDimanche: string
  horairesOuvertureJeudi: string
  horairesOuvertureLundi: string
  horairesOuvertureMardi: string
  horairesOuvertureMercredi: string
  horairesOuvertureSamedi: string
  horairesOuvertureVendredi: string
  identifiant: string
  indiceDeLocalisation: string
  localite: string
  nom: string
  periodeActiviteHoraireDeb: string
  periodeActiviteHoraireFin: string
  poidsMaxi: number
  typeDePoint: string
  codePays: string
  langue: string
  libellePays: string
  loanOfHandlingTool: boolean
  parking: boolean
  reseau: string
  distributionSort: string
  lotAcheminement: string
  versionPlanTri: number
}

export interface IPosition {
  lat: number
  lng: number
}

export class PointRelaisSelectable {
  private _IdPointRelais: string = ""

  public get IdPointRelais(): string {
    return this._IdPointRelais
  }
  constructor(id: number) {
    this._IdPointRelais = "PointRelais_" + id
  }
}
export class PointRelais extends PointRelaisSelectable {
  public Name: string
  public Adress: string
  public OpeningHours: string[]
  public coordGeolocalisationLatitude: number
  public coordGeolocalisationLongitude: number
  public Country: string
  public City: string
  public CompanyName: string
  public PostalCode: string
  public Code: string
  public identifiant: string
  constructor(data: IPointRelaisColissimo, id: number) {
    super(id)
    this.Name = data.nom
    this.Adress = [data.adresse1, data.adresse2, data.adresse3].join(" ")
    this.Country = data.libellePays
    this.City = data.localite
    this.CompanyName = data.nom
    this.PostalCode = data.codePostal
    this.Code = data.codePays
    this.OpeningHours = [
      `Lundi : ${formatHeureOuverture(data.horairesOuvertureLundi)}`,
      `Mardi : ${formatHeureOuverture(data.horairesOuvertureMardi)}`,
      `Mercredi : ${formatHeureOuverture(data.horairesOuvertureMercredi)}`,
      `Jeudi : ${formatHeureOuverture(data.horairesOuvertureJeudi)}`,
      `Vendredi : ${formatHeureOuverture(data.horairesOuvertureVendredi)}`,
      `Samedi : ${formatHeureOuverture(data.horairesOuvertureSamedi)}`,
      `Dimanche : ${formatHeureOuverture(data.horairesOuvertureDimanche)}`,
    ]
    this.identifiant = data.identifiant || ""
    this.coordGeolocalisationLatitude = data.coordGeolocalisationLatitude
    this.coordGeolocalisationLongitude = data.coordGeolocalisationLongitude
  }
}

function formatHeureOuverture(h) {
  if (h == "00:00-00:00 00:00-00:00") return "Fermé"

  return h.replace("00:00-00:00", "")
}
