import styled from "styled-components"

export const Image = styled.img`
  object-fit: cover;
  max-height: 100%;
  width: 100%;
`

export const ButtonStyled = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
`
